import _resolveConfig from "./lib/util/resolveConfig";
import _getAllConfigs from "./lib/util/getAllConfigs";
var exports = {};
const resolveConfigObjects = _resolveConfig.default;
const getAllConfigs = _getAllConfigs.default;

exports = function resolveConfig(...configs) {
  const [, ...defaultConfigs] = getAllConfigs(configs[0]);
  return resolveConfigObjects([...configs, ...defaultConfigs]);
};

export default exports;